import React from 'react';
import {IsInvalidTokenStyled, PagesWrapperStyled} from "../../styles/EditPreivewStyles";
import coverWizard from "../../images/coverWizard.svg";
import {useLocation} from "react-router";
import {chooseLang} from "../../common/chooseLang";

const BooksIsReturnedPage = () => {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";

    const langData = chooseLang(lang);

    return (
        <PagesWrapperStyled>
            <IsInvalidTokenStyled>
            <div>
                <p>{langData.returned_book.first}</p>
            </div>
            <img src={coverWizard} alt="wizard"/>
            </IsInvalidTokenStyled>
        </PagesWrapperStyled>
    );
};

export default BooksIsReturnedPage;
