import React from 'react';
import ModalIframe from "./ModalIframe";
import ModalRegular from "./ModalRegular";

const Modal = (props) => {

    const isIframe = window.self !== window.top;

    return (
        <>
            {isIframe && (
                <ModalIframe
                    {...props}
                />
            )}
            {!isIframe && (
                <ModalRegular
                    {...props}
                />
            )}
        </>
    );
};

export default Modal;