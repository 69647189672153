import { createContext, useState } from "react";

export const MainContext = createContext(null);

export const MainContextProvider = ({ children }) => {
    const [lang, s_lang] = useState("he");
    const [bookData, s_bookData] = useState(null);
    const [config, s_config] = useState(null);
    const [parentData, s_parentData] = useState(null);
    const [dimensionsData, s_dimensionsData] = useState({});
    const [textAreaValidation, s_textAreaValidation] = useState({});

    return (
        <MainContext.Provider
            value={ {
                bookDataContext: [bookData, s_bookData],
                configContext: [config, s_config],
                parentDataContext: [parentData, s_parentData],
                dimensionsDataContext: [dimensionsData, s_dimensionsData],
                textAreaValidationContext: [textAreaValidation, s_textAreaValidation],
            } }
        >
            { children }
        </MainContext.Provider>
    );
};
