const ch = () => {
    return {
        "arias": {
            "cover": "封面编号",
            "textArea": "书籍文字，页",
            "choose_page_image": "选择页面图像"
        },
        "page": "页面",
        "reset": "重置",
        "finish": "完成",
        "finished": {
            "book": {
                "one": "很酷，现在轮到我们开始魔法",
                "second": "{{name}}的书将在10个工作日内送达："
            },
            "mebook_info": {
                "one": "随时联系我们，方便您时-"
            },
            "pdf": {
                "one": "看起来很棒",
                "second": "您的书将发送到",
                "third": "在几分钟内",
                "fourth": "希望您喜欢 :)"
            }
        },
        "general_generating_book_error": {
            "error": "我们今天运气不佳，出现了一些错误，我们正在修复"
        },
        "already_finished": {
            "first": "此书已完成",
            "second": ""
        },
        "returned_book": {
            "first": "此书已取消"
        },
        "main_title": "请编辑您的书以完成",
        "choose_cover": "请选择封面",
        "errorCode": {},
        "choose": "选择",
        "save": "保存",
        "to_buy": "完成您的订单",
        "preview_and_make_pdf": "预览并发送书籍",
        "preview_and_make_book": "预览并打印书籍",
        "tutorial_title": "教程",
        "print": "打印",
        "tutorial_items": [
            "请为每页选择一个图像",
            "您可以编辑页面的文字",
            "您可以保存并以后编辑",
            "您可以编辑页面的封面",
            "完成后，请按预览并发送书籍"
        ],
        "end_process": {
            "main_text": "太棒了！选择您想要的选项之一 \n" + "为您的书籍封面设定",
            "buttonText": "结账",
            "choose_cover_pdf": "数字电子书",
            "choose_cover_hard": "精装书",
            "p_description_pdf": "高质量的数字电子书，以PDF格式精心设计，随时随地可读。",
            "p_description_hard": "印刷书籍，硬皮精装，高品质印刷，现代设计",
            "price_pdf": "",
            "price_book": "+ 运费",
            "price_book_sub": ""
        },
        "loading_texts": {
            "text1": "激发想象力...",
            "subtext1": "只需几个魔幻的时刻！",
            "text2": "释放创造力...",
            "subtext2": "您的个性化冒险正在酝酿中！",
            "text3": "拥抱期待...",
            "subtext3": "您的AI书籍正在制作中！",
            "text4": "沉浸在奇迹中...",
            "subtext4": "您的个性化冒险正在酝酿中！",
            "text5": "迷人的时刻在前方...",
            "subtext5": "我们正在为您打造一个故事！"
        },
        "tutorial_checkbox": "不再显示",
        "terms": "同意网站的",
        "terms_part_2": "使用条款",
        terms_link: "https://mebook.ai/en/refund_returns/",
        "click_me": "点击书本",
        "tutorial_button_text": "开始",
        "terms_modal_h": "本协议概述了通过MeBook网站和移动应用程序进行购买的条款和条件。它还提供了有关我们的退货和退款政策的详细信息。",
        "terms_modal": [
            {
                "title": "下订单",
                "p": "您可以通过我们的网站或移动应用程序订购个性化儿童图书。所有订单都需要提交有关您的孩子的详细信息以及付款信息。下订单即表示您确认提供的所有信息都是准确的。"
            },
            {
                "title": "定价和付款",
                "p": "显示的价格包括所有适用的税费和费用。购买时必须以全额支付，可以通过信用卡或其他提供的付款选项支付。MeBook保留随时更新价格的权利。"
            },
            {
                "title": "订单确认和处理",
                "p": "您将收到订单确认电子邮件，其中包含您购买的详细信息。一旦付款成功处理，书籍的创建和印刷将立即开始。预计的交货时间表将在订单确认中通知。"
            },
            {
                "title": "运输和交付",
                "p": "我们将把完成的书籍邮寄到您在结账时指定的地址。交货时间可能会因您所在地区而异。MeBook对第三方交付合作伙伴造成的延误不负责任。"
            },
            {
                "title": "退货和退款",
                "p": "如果您的书籍在运输过程中损坏或与提供的预览有显著差异，请在交付后7天内与我们联系以启动退货流程。我们将评估所声称的损坏或差异，并可能要求提供照片证据。" +
                    "如果批准，一旦书籍退回到我们的履行中心，您将获得全额退款。如果退货原因是由于我们的过失或错误导致的损坏，退货运费将得到补偿。"
            },
            {
                "title": "取消或更改订单",
                "p": "鉴于我们书籍的个性化特性，一旦书籍创建开始，订单将无法取消或更改。请在下订单前仔细审核订单详情。"
            },
            {
                "title": "责任限制",
                "p": "MeBook不对因购买或使用我们的产品而导致的任何间接、附带或后果性损害承担责任。我们的最大责任仅限于书籍的购买价格。"
            },
            {
                "title": "联系我们",
                "p": "如果您对订单有任何疑问或问题，请发送电子邮件至info@mebook.ai与我们联系。我们致力于提供有趣、轻松的订购体验，并确保您完全满意。"
            }
        ],
        "tutorial_modal": {
            "cov": "请选择封面",
            "items": "请为每一页选择图片",
            "texts": "您可以编辑每一页的文字",
            "reset": "您可以将文字重置为原始状态",
            "save": "您可以保存书籍以便将来编辑",
            "finish": "完成书籍请按完成按钮",
            "skip": "跳过",
            "next": "下一步",
            "back": "返回",
            "last": "最后"
        },
        "tutorial_modal_preview": {
            "cov": "请选择封面",
            "items": "请为每一页选择图片",
            "texts": "您可以编辑每一页的文字",
            "reset": "您可以将文字重置为原始状态",
            "save": "您可以保存书籍以便将来编辑",
            "finish": "完成书籍请按完成按钮"
        },
        "preview": {
            "image_blurred_text": "购买后将显示图像"
        },
        "back_to_website": "返回网站",
        "more_images_editor": "不太合适？",
        "more_images_editor_2": "生成更多！",
        "more_images_editor_warn": "我们致力于使您的书籍真正特别。如果生成的图像与您心中所想的不太一样，请随时创建新的一组！只需单击\"再次生成\"，让我们的AI再次发挥其魔力。\n" +
            "\n" +
            "请记住，为了确保流畅高效的体验，您每本书最多可以为5页重新生成图像。",
        "more_images_button_warn": "确认",
        more_data_prepearing: "Creating the page"
    };
};

export default ch;
