import React, {useContext, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import styled from "styled-components";
import exit from "../../images/exit.svg";
import FocusTrap from "focus-trap-react";
import {MainContext} from "../../ContextState";
import {getMobileQuery} from "../../common/utils";
import {chooseLang} from "../../common/chooseLang";

const WraperStyled = styled.div`
    &#modal-container {
        position: fixed;
        //display: table;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        //transform: scale(0);
        z-index: 21;

        .modal-background {
            //display: table-cell;
            //background: rgba(0, 0, 0, .8);
            text-align: center;
            vertical-align: middle;

            .modal {
                background: white;
                display: inline-block;
                position: relative;

                padding: 25px;
                border: 1px solid #b5b5b5;
                border-radius: 15px;

                @media (max-width: 480px) {
                    border: none;
                    border-radius: 0;
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 39px 25px 30px;
                }
                @media (max-width: 330px) {
                    border: none;
                    border-radius: 0;
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 20px 25px 30px;
                }
            }
        }
    }

    .exit_popup {
        cursor: pointer;
        position: absolute;
        right: 36px;
        top: 21px;
        z-index: 1;

        img {
            width: 18px;
        }
    }

    &#modal-container .modal-background .modal {
        @media (max-width: 1130px) {
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            border-radius: 0;
            box-sizing: border-box;
            top: inherit;
        }
    }
`;
const ModalIframe = ({isOpen, onClose, children, lang, closeModal, backGroundClose, removeClose, ariaTitle, role}) => {
    const closeButtonRef = useRef(null);
    const modalContainerRef = useRef(null);

    const childrenRef = useRef(null);

    const modalRef = useRef(null);

    const {parentDataContext, dimensionsDataContext} = useContext(MainContext);
    const [parentData] = parentDataContext;
    const [dimensionsData] = dimensionsDataContext;

    const [isHanled, s_isHanled] = useState(false);
    const [classes, s_classes] = useState("two out");

    const [modalHeight, s_modalHeight] = useState(0);
    const [piko, s_piko] = useState(0);
    const [modalContainerHeight, s_modalContainerHeight] = useState(0);

    const innerWidth = getMobileQuery();
    const isMobile = innerWidth < 550;

    const contentHeight = childrenRef?.current?.offsetHeight || 0;


    let topPos = 150;
    if (parentData?.scrollPosition === 0) {
        topPos = 3;
    } else if (parentData?.scrollPosition) {
        topPos = parentData?.scrollPosition;
        if(parentData?.distanceTopToIframe < topPos){
            topPos = topPos - parentData.distanceTopToIframe + 25;
        }else {
            topPos = 3;
        }
    }

    let bottomPos = 'inherit';
    if (isMobile) {
        topPos = 'inherit';
        if (parentData) {
            const pos = dimensionsData?.height - parentData.screenHeight + parentData.distanceTopToIframe - parentData.scrollPosition + 25;
            bottomPos = pos;
            if (contentHeight + 50 > parentData.screenHeight) {
                bottomPos = bottomPos - 30;
            }
        } else {
            bottomPos = 30;
            if (contentHeight + 50 > window.screen.height) {
                bottomPos = 0;
            }
        }
        if(bottomPos < 0){
            bottomPos = 0;
        }
    } else {
        if (!parentData?.scrollPosition && parentData?.scrollPosition !== 0) {
            let pospos = ((window.screen.height - contentHeight) / 2) - 100;
            if(pospos < 0){
                pospos = 0;
            }
            topPos = pospos;
        }
    }

    if (topPos + contentHeight  + 100> dimensionsData?.height) {
        topPos = dimensionsData?.height - contentHeight - 100;
    }

    useEffect(() => {
        document.body.classList.add('modal-active');
        s_isHanled(true);
        s_classes('two');
    }, []);

    useEffect(() => {
        s_modalContainerHeight(modalContainerRef?.current?.offsetHeight)
    }, [modalContainerRef?.current?.offsetHeight]);

    useEffect(() => {
        if (closeModal) {
            onCloseHandle(null, true);
        }
    }, [closeModal]);

    useEffect(() => {
        if (closeButtonRef.current) {
            setTimeout(() => {
                closeButtonRef.current.focus();
            }, 400);
        }
    }, [closeButtonRef.current]);

    useEffect(() => {
        setTimeout(() => {
            s_piko(piko + 1);
        }, 500);
    }, []);

    const onCloseHandle = (e, isCross) => {
        let toClose = false;
        if (isCross) {
            toClose = true;
        }
        if (!toClose && backGroundClose) {
            toClose = true;
        }

        if (toClose) {
            s_classes('two out');
            document.body.classList.remove('modal-active');
            s_isHanled(false);
            onClose();
        }
    }

    const langData = chooseLang(lang);

    const modalStyles = {};
    modalStyles.top = topPos;
    modalStyles.bottom = bottomPos;
    if (modalContainerHeight < 690) {
        modalStyles.maxHeight = modalContainerHeight;
        modalStyles.overflow = 'auto';
    }


    return ReactDOM.createPortal(
        <WraperStyled
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    onCloseHandle(e, true);
                }
            }}
            role={role || "dialog"}
            aria-modal="true"
            aria-label={ariaTitle}
            id="modal-container" className={`lang-${lang} ${classes}`}
            ref={modalContainerRef}
        >
            {isHanled && (
                <div className="modal-background" onClick={onCloseHandle}>
                    <FocusTrap>
                        <div
                            ref={modalRef}
                            style={modalStyles}
                            className="modal">
                            {!removeClose && (
                                <div
                                    role='button'
                                    aria-label={langData.arias.close}
                                    ref={closeButtonRef}
                                    onKeyDown={(e) => {
                                        if (e.key === " " || e.key === "Enter") {
                                            onCloseHandle(e, true);
                                        }
                                    }}
                                    tabIndex='0'
                                    className="exit_popup" onClick={(e) => {
                                    e.stopPropagation();
                                    onCloseHandle(e, true);
                                }}><img src={exit} alt="exit"/></div>
                            )}

                            <div
                                style={{position: "relative"}}
                                ref={childrenRef}>
                                {children}
                            </div>
                        </div>
                    </FocusTrap>
                </div>
            )}
        </WraperStyled>,
        document.getElementById('modal-container-d')
    );
};

export default ModalIframe;
