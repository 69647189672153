import React from 'react';
import {IsInvalidTokenStyled, PagesWrapperStyled} from "../../styles/EditPreivewStyles";
import coverWizard from "../../images/coverWizard.svg";
import {useLocation} from "react-router";
import {chooseLang} from "../../common/chooseLang";

const BooksIsApprovedPage = () => {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";

    const langData = chooseLang(lang);

    return (
        <PagesWrapperStyled>
            <IsInvalidTokenStyled>
            <div>
                <p>{langData.already_finished.first}</p>
                <p>{langData.already_finished.second}</p>
                <p>
                    <a href="mailto:info@MeBook.ai">info@MeBook.ai</a>
                </p>
            </div>
            <img src={coverWizard} alt="wizard"/>
            </IsInvalidTokenStyled>
        </PagesWrapperStyled>
    );
};

export default BooksIsApprovedPage;