import React, { useEffect, useRef, useState } from "react";
import BookCoverPrev from "../pages/editor/components/book/BookCoverPrev";
import styled from "styled-components";
import { motion, useMotionValue } from "framer-motion";
import { calculateFont, calculateFontBaseSize, getFontSize } from "../common/utils";

import Range from "../pages/editor/components/RangeSlider";
import ColorPicker from "../pages/editor/components/ColorPicker";
import { chooseLang } from "../common/chooseLang";
import { ResetSvg } from "../svg";

const ModalStyled = styled.div`
    min-width: 500px;
    padding: 20px;

    h3 {
        font-size: 24px;
        font-weight: 600;
    }

    p {
        font-size: 20px;
    }

    b {
        font-size: 22px;
    }

    @media (max-width: 460px) {
        width: 100%;
        min-width: auto;
        padding: 0;
    };
`;

const ContentWrapperStyled = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
`;

const TextInputStyled = styled(motion.div)`
    position: absolute;

    max-width: 100%;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0;
    text-shadow: 1px 1px 2px #000;

    input {
        background: transparent;
        border: 0;
        width: 100%;
        text-align: center;
        outline: none;
        text-shadow: 1px 1px 2px #000;
    }

    .draggable-point {
        color: #fff;
        position: absolute;
        top: -31px;
        width: 80px;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0px 5px;
        height: 55px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(000, 000, 000, 0.5);
        border-radius: 7px;
        cursor: grab;
        user-select: none;

        div {
            line-height: 0;
            font-size: 61px;
            padding: 0;
            margin: 0;
        }

        @media (max-width: 460px) {
            width: 58px;
            grid-gap: 0px 0px;
            height: 46px;
        };
    }
`;

const ErrorTextStyled = styled.div`
    position: relative;
    margin-top: -20px;
    color: red;
`;

const EditorHeading = styled.div`
    width: 100%;
    text-align: ${ props => props?.lang === "he" ? "right" : "left" };

    font-size: 1.5rem;
    font-weight: 800;
    margin: 0;

    display: grid;
    grid-template-columns: 1fr 1fr;

    &.pad {
        padding-bottom: 10px;
    }

    .title_editor_reset {
        text-align: left;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        flex-flow: row-reverse;

        &:hover {
            text-decoration: underline;
        }

        svg {
            width: 20px;
            height: 20px;
            margin: 0 3px;
        }
    }
`;
const Spacer = styled.div`
    width: ${ (props) => props.space }rem;
    height: ${ (props) => props.space }rem;
`;
const ActionsStyled = styled.div`

`;

const PreviewMode = styled.div`
    border-radius: 1rem;
    background: #f2f2f266;
    margin: 10px 0;
    border: 1px dashed #ddd;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 80px;

    p {
        margin: 0;
        font-size: 1rem;
    }
`;

const BoundsStyled = styled.div`

    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;

    &[data-preview="false"] {
        border: 1px dashed #fffa;
    }
`;

const ButtonRow = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    align-content: center;
`;
const ButtonStyled = styled.div`
    background-color: #ddd;
    flex: 1;
    color: white;
    display: flex;
    padding: 10px 20px;
    border-radius: 0.7rem;
    flex-direction: row;
    cursor: pointer;

    transition: all .2s ease-in-out;
    align-items: center;
    justify-content: center;
    align-content: center;

    &.disabled {
        background: #ccc!important;
        cursor: not-allowed;
        pointer-events: none;
    }

    &:hover {
        filter: brightness(90%);
    }

    p {
        margin: 0;
        font-size: 1rem;

    }

    &[data-type="submit"] {

        color: black;
        background: rgb(255, 151, 31);
        background: linear-gradient(101deg, rgba(255, 151, 31, 1) 0%, rgba(255, 169, 39, 1) 100%);
    }

`;

const maxChars = 35;

const EditCover = ({ coverData, imageUrl, lang, coverDesign, templateTitle, onSave, bookData }) => {
    const coversRef = useRef(null);
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const dragRef = useRef(null);

    const [wrapperWidth, setWrapperWidth] = useState(0);
    const [text, setText] = useState(coverData?.text || "");
    const [textTooBig, setTextTooBig] = useState(false);
    const [preview, setPreview] = useState(false);

    const [position, setPosition] = useState(coverData?.position || { x: 0, y: 10 });
    const [textColor, setTextColor] = useState(coverData?.textColor || "#ffffff");

    const fontSize = calculateFont(coverData.textSize, wrapperWidth);
    const [textSize, setTextSize] = useState(fontSize);

    const langData = chooseLang(lang);

    useEffect(() => {
        setTextSize(fontSize);
    }, [fontSize]);

    useEffect(() => {
        if (coversRef?.current) {
            setWrapperWidth(coversRef.current.offsetWidth);
        }
    }, [coversRef?.current]);

    const handleInput = () => {
        const currentText = inputRef.current.innerText;
        if (currentText.length >= maxChars) {
            setTextTooBig(true);
        } else {
            setTextTooBig(false);
        }
    };

    useEffect(() => {
        const ref = inputRef.current;
        ref.addEventListener("input", handleInput);
        return () => {
            ref.removeEventListener("input", handleInput);
        };
    }, []);

    useEffect(() => {
        if (wrapperRef?.current) {
            const parentHeight = wrapperRef.current.clientHeight;
            if (!parentHeight) return;

            const divHeight = dragRef.current.clientHeight;

            if (!divHeight) return;
            let divHeightPercentage = Math.round((divHeight / parentHeight) * 100);
            if (!divHeightPercentage) {
                divHeightPercentage = 1;
            }

            let yPositionPercent = 10;
            if (position.y !== undefined) {
                yPositionPercent = position.y + divHeightPercentage;
                if (position.y === 0) {
                    yPositionPercent = 0;
                }
            }

            const yPxPosition = (parentHeight - divHeight) * (yPositionPercent / 100);
            y.set(-yPxPosition);
        }
    }, [wrapperRef?.current, textSize, text]);

    const x = useMotionValue(0);
    const y = useMotionValue(-60);
    const handleDrag = (event, info) => {
        if (wrapperRef.current) {
            // Get the height of the parent ref
            const parentHeight = wrapperRef.current.clientHeight;
            const divHeight = dragRef.current.clientHeight;
            // Assuming y.get() provides the current y position from the top
            const yPosition = y.get(); // Current y position value

            // Calculate the percentage of y relative to the height
            const yPercentage = Math.abs(Math.round((yPosition / (parentHeight - divHeight)) * 100));
            const divHeightPercentage = Math.round((divHeight / parentHeight) * 100);

            // Inverse calculation might not be needed; it depends on your use case
            // If y is from the bottom, you might want the inverse, but it usually doesn't make sense to add 100%
            // To reflect the actual position percentage from the top, only yPercentage should be sufficient
            const yPercentageInverse = Math.round(100 + (yPosition / (parentHeight - divHeight)) * 100);

            let bottomYPosition = Math.abs(yPercentage - divHeightPercentage);

            if (yPercentage === 0) {
                bottomYPosition = 0;
            }

            setPosition({ x: 0, y: bottomYPosition });
        }
    };

    const handleSave = () => {
        const relevantSize = calculateFontBaseSize(textSize, wrapperWidth);
        onSave({ text: inputRef.current.innerText.trim(), textColor, textSize: relevantSize, position });
    };

    const resetCover = () => {
        const coverDesign = bookData.template.coverDesign;
        const textSizeD = coverDesign?.textSize;
        const fontSize = getFontSize(textSizeD);
        const fontSizeSize = calculateFont(fontSize, wrapperWidth);
        const gender = bookData?.inputData?.gender;
        const templateTitle = gender === "female" ? bookData?.template?.titleFemale : bookData?.template?.titleMale;

        const position = { x: 0, y: 10 };
        inputRef.current.innerText = templateTitle;
        setText(templateTitle);
        setTextSize(fontSizeSize);
        setTextColor("#ffffff");

        setPosition(position);
        setTimeout(() => {
            y.set(-60);
        }, 100);
    };

    const gizmoobject = {
        width: 8, height: 8, backgroundColor: "white", position: "absolute", borderRadius: "10px"
    };
    const gizmos = [
        {
            title: "left-top",
            style: {
                left: -(gizmoobject.width / 2), top: -(gizmoobject.height / 2), cursor: "move", ...gizmoobject
            }
        },
        {
            title: "left-bottom", style: {
                left: -(gizmoobject.width / 2), bottom: -(gizmoobject.height / 2), cursor: "move", ...gizmoobject
            }
        },
        {
            title: "right-top", style: {
                right: -(gizmoobject.width / 2), top: -(gizmoobject.height / 2), cursor: "move", ...gizmoobject
            }
        },
        {
            title: "right-bottom", style: {
                right: -(gizmoobject.width / 2), bottom: -(gizmoobject.height / 2), cursor: "move", ...gizmoobject
            }
        },
        {
            title: "top-center", style: {
                left: "50%", transform: "translateX(-50%)", top: -(gizmoobject.height / 1.5), cursor: "move", ...gizmoobject
            }
        },
        {
            title: "rotation", style: {
                left: "50%", transform: "translateX(-50%)", top: "-35px", cursor: "move", ...gizmoobject
            }
        },
        {
            title: "top-center-top", style: {
                left: "50%", transform: "translateX(-50%)", height: "35px ", width: "1px", top: "-35px", backgroundColor: "#fff", position: "absolute",
            }
        }
    ];

    return (<ModalStyled ref={ coversRef }>
        <ActionsStyled>
        </ActionsStyled>
        <ContentWrapperStyled
            ref={ wrapperRef }
        >
            <BookCoverPrev
                wrapperWidth={ wrapperWidth }
                isSelected={ true }
                index={ 0 }
                imageUrl={ imageUrl }
                lang={ lang }
                coverDesign={ coverDesign }
                text={ templateTitle }
                contWidth={ wrapperWidth }
                hideText={ true }
            />

            { preview === true ? <motion.div
                style={ {
                    width: "10%", position: "absolute", height: "300%", top: 0, left: 0, background: "linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.5) 95%, rgba(255,255,255,0.1) 100%)", transform: "rotate(45deg)", filter: "blur(2px)"
                } }
                animate={ {
                    left: ["-30%", "100%"], top: ["-150%", "100%"], opacity: [1, 0],
                } }
                initial={ { opacity: 0 } }
                exit={ { opacity: 0 } }
                transition={ {
                    duration: 3, ease: "easeInOut"
                } }
            /> : "" }

            <TextInputStyled
                ref={ dragRef } drag={ !preview }
                onDragEnd={ handleDrag } dragConstraints={ wrapperRef }
                dragMomentum={ false } dragElastic={ false }
                style={ { x, y } }
                className="draggable-point"
            >
                <BoundsStyled data-preview={ preview }>
                    { gizmos && !preview && gizmos.map((item, index) => {
                        return (<div
                            key={ index }
                            style={ item.style }
                        >
                        </div>);
                    }) }
                    { preview === true ? <motion.div
                        style={ {
                            width: "100%", position: "absolute", height: "100%", background: "linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0.1) 100%)", backgroundSize: "200% 100%", zIndex: 0
                        } }
                        animate={ {
                            backgroundPosition: ["200% 0%", "-200% 0%"],
                            opacity: [1, 0],
                        } }
                        initial={ { opacity: 0 } }
                        exit={ { opacity: 0 } }
                        transition={ {
                            duration: 1, ease: "easeInOut",
                        } }
                    /> : "" }
                    <div
                        inputMode={ "text" }
                        style={ { color: textColor, fontSize: `${ textSize }px`, width: "100%", outline: "none", zIndex: 1, padding: "0 10px", boxSizing: "border-box" } }
                        ref={ inputRef }
                        contentEditable={ !preview }
                    >{ text }</div>
                </BoundsStyled>
            </TextInputStyled>
        </ContentWrapperStyled>

        <Spacer space={ 2 }></Spacer>
        { preview === false ? <>
            { textTooBig && (
                <ErrorTextStyled>
                    { langData.title_editor_text.text_is_too_big }
                </ErrorTextStyled>
            )}
            <EditorHeading className="pad" lang={ lang }>
                <div className="title_editor_text">
                    { langData.title_editor_text.f_size }
                </div>
                <div
                    onClick={ () => {
                        resetCover();
                    } }
                    className="title_editor_reset">
                    <ResetSvg/> { langData.title_editor_text.reset }
                </div>
            </EditorHeading>
            <Range
                lang={ lang }
                min={ 15 }
                max={ 100 }
                value={ textSize }
                default={ 100 }
                onChange={ (e) => {
                    setTextSize(parseInt(e));
                } }
            />
            <EditorHeading lang={ lang }>{ langData.title_editor_text.f_color }</EditorHeading>
            <ColorPicker lang={ lang } title={ langData.title_editor_text.hex_color } placeholder={ langData.title_editor_text.hex_color } key={ "picker" } value={ textColor } onChange={ (e) => {
                setTextColor(e);
            } }></ColorPicker>
            <Spacer space="1"></Spacer>

        </> : "" }
        { preview === true ? <>
            <PreviewMode>
                <Eye></Eye>
                <p>{ langData.title_editor_text.preview_mode }</p>
            </PreviewMode>
            <Spacer space={ 2 }></Spacer>
        </> : "" }

        <ButtonRow>
            <ButtonStyled
                className={ textTooBig ? "disabled" : "" }
                data-type="submit" onClick={ () => {
                setPreview(preview ? false : true);
            } }>
                <p>{ preview ? langData.title_editor_text.goback : langData.title_editor_text.preview }</p>
            </ButtonStyled>
            <Spacer space="1"></Spacer>
            <ButtonStyled
                className={ textTooBig ? "disabled" : "" }
                data-type="submit" onClick={ () => {
                handleSave();
            } }>
                <p>{ langData.title_editor_text.save }</p>
            </ButtonStyled>
        </ButtonRow>

    </ModalStyled>);
};

export default EditCover;


const Eye = () => {
    return <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 9.005a4 4 0 1 1 0 8 4 4 0 0 1 0-8ZM12 5.5c4.613 0 8.596 3.15 9.701 7.564a.75.75 0 1 1-1.455.365 8.503 8.503 0 0 0-16.493.004.75.75 0 0 1-1.455-.363A10.003 10.003 0 0 1 12 5.5Z" fill="currentColor"/>
    </svg>;
};
