import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import styled from "styled-components";
import exit from "../../images/exit.svg";
import FocusTrap from "focus-trap-react";
import heb from "../../languages/heb";
import eng from "../../languages/eng";
import {chooseLang} from "../../common/chooseLang";

const WraperStyled = styled.div`
    &#modal-container {
        position: fixed;
        display: table;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: scale(0);
        z-index: 21;

        &.two {
            transform: scale(1);

            .modal-background {
                background: rgba(0, 0, 0, .0);
                animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

                .modal {
                    opacity: 0;
                    animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
                }
            }

            + #root {
                animation: scaleBack .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            }

            &.out {
                animation: quickScaleDown 0s .5s linear forwards;

                .modal-background {
                    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

                    .modal {
                        animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
                    }
                }

                + #root {
                    animation: scaleForward .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
                }
            }
        }

        .modal-background {
            display: table-cell;
            background: rgba(0, 0, 0, .8);
            text-align: center;
            vertical-align: middle;

            .modal {
                background: white;
                display: inline-block;
                position: relative;

                padding: 25px;
                border: 1px solid #b5b5b5;
                border-radius: 15px;

                @media (max-width: 480px) {
                    border: none;
                    border-radius: 0;
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 39px 25px 10px;
                }
            }
        }
    }

    .exit_popup {
        cursor: pointer;
        position: absolute;
        right: 36px;
        top: 21px;

        img {
            width: 18px;
        }
    }

    &#modal-container .modal-background .modal {
        @media (max-width: 1130px) {
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            border-radius: 0;
            box-sizing: border-box;
            top: inherit;
        }
    }
`;
const ModalRegular = ({isOpen, onClose, children, lang, closeModal, backGroundClose, removeClose, ariaTitle, role}) => {
    const closeButtonRef = useRef(null);
    const modalContainerRef = useRef(null);
    const [isHanled, s_isHanled] = useState(false);
    const [classes, s_classes] = useState("two out");
    const [modalContainerHeight, s_modalContainerHeight] = useState(0);

    useEffect(() => {
        document.body.classList.add('modal-active');
        s_isHanled(true);
        s_classes('two');
    }, []);

    useEffect(() => {
        if (closeModal) {
            onCloseHandle(null, true);
        }
    }, [closeModal]);

    useEffect(() => {
        if (closeButtonRef.current) {
            setTimeout(() => {
                closeButtonRef.current.focus();
            }, 400);
        }
    }, [closeButtonRef.current]);

    useEffect(() => {
        s_modalContainerHeight(modalContainerRef?.current?.offsetHeight)
    }, [modalContainerRef?.current?.offsetHeight]);

    const onCloseHandle = (e, isCross) => {
        let toClose = false;
        if (isCross) {
            toClose = true;
        }
        if (!toClose && backGroundClose) {
            toClose = true;
        }

        if (toClose) {
            s_classes('two out');
            setTimeout(() => {
                document.body.classList.remove('modal-active');
                s_isHanled(false);
                onClose();
            }, 700);
        }
    }

    const langData = chooseLang(lang);

    const modalStyles = {};

    if (modalContainerHeight + 100 > window.innerHeight) {
        modalStyles.maxHeight = window.innerHeight -50;
        modalStyles.overflow = 'auto';
    }

    return ReactDOM.createPortal(
        <WraperStyled
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    onCloseHandle(e, true);
                }
            }}
            ref={modalContainerRef}
            role={role || "dialog"}
            aria-modal="true"
            aria-label={ariaTitle}
            id="modal-container" className={`lang-${lang} ${classes}`}>
            {isHanled && (
                <div className="modal-background" onClick={onCloseHandle}>
                    <FocusTrap>
                        <div
                            style={modalStyles}
                            className="modal">
                            {!removeClose && (
                                <div
                                    role='button'
                                    aria-label={langData.arias.close}
                                    ref={closeButtonRef}
                                    style={{zIndex:1}}
                                    onKeyDown={(e) => {
                                        if (e.key === " " || e.key === "Enter") {
                                            onCloseHandle(e, true);
                                        }
                                    }}
                                    tabIndex='0'
                                    className="exit_popup" onClick={(e) => {
                                    e.stopPropagation();
                                    onCloseHandle(e, true);
                                }}><img src={exit} alt="exit"/></div>
                            )}

                            {children}
                        </div>
                    </FocusTrap>
                </div>
            )}
        </WraperStyled>,
        document.getElementById('modal-container-d')
    );
};

export default ModalRegular;
