import React from 'react';
import styled from "styled-components";

import Lottie from 'react-lottie-player'


// import animation from '../images/magic-book.json';
import animation from '../images/circle.json';

const LoaderWrapper = styled.div`
  text-align: center;
`;


const Loader = ({size = 80}) => {
    return (
        <LoaderWrapper className='loader-lottie'>
            <Lottie
                loop
                animationData={animation}
                play
                style={{width: size, height: size, margin: "auto"}}
            />
        </LoaderWrapper>
    );
};

export default Loader;