import React from "react";
import styled from "styled-components";
import { ClickMeArrow } from "../../../svg";

const WrapperStyled = styled.div`
    position: absolute;
    top: 30%;
    right: 7%;
    width: 100px;
    animation: sidearrow 0.6s infinite alternate ease-in-out;

    &.da {
        right: inherit;
        left: 7%;

        p {
            top: -11px;
        }

        svg {
            transform: scaleX(-1);
        }
    }

    &.en {
        right: inherit;
        left: 7%;

        svg {
            transform: scaleX(-1);
        }
    }

    @media (max-width: 767px) {
        width: 50px;
        top: 20%;
    }

    p {
        margin: 0;
        padding: 0;
        position: absolute;
        width: 100%;
        top: 9px;

        @media (max-width: 767px) {
            top: -17px;
            font-size: 13px;
        }
    }

    @-webkit-keyframes sidearrow {
        0% {
            transform: translateX(0);
            opacity: 0.9
        }
        100% {
            transform: translateX(-0.4em);
            opacity: 0.99
        }
    }
`;
const ClickOnMe = ({ langData, lang }) => {

    return (
        <WrapperStyled className={ lang }>
            <p>{ langData?.click_me }</p>
            <ClickMeArrow/>
        </WrapperStyled>
    );
};

export default ClickOnMe;
