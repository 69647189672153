import axios from "axios";
import { API_URL } from "./consts";
import { getTokenCookie } from "./common/cookie";

const setTokenHeader = () => {
    axios.defaults.headers.common["Authorization"] = getTokenCookie();
};

export const getBook = async (id) => {
    setTokenHeader();
    const response = await axios.get(`${ API_URL }/user/load?bookId=${ id }`, {});
    return response.data.data;
};

export const getBookJob = async (id) => {
    try {
        setTokenHeader();
        const response = await axios.get(`${ API_URL }/user/book-jobs?bookId=${ id }`, {});
        return response.data.data;
    } catch (e) {

    }
};

export const verifyToken = async (token, bookId) => {
    setTokenHeader();
    const response = await axios.post(`${ API_URL }/user/verify`, { bookId, token });
    return response.data;
};

export const finishBook = async (bookId, token, bookData) => {
    setTokenHeader();
    const response = await axios.post(`${ API_URL }/user/approve-book`, { bookId, token, approvedData: bookData });
    return response.data.data;
};

export const saveBook = async (token, bookId, bookData, coverData) => {
    setTokenHeader();
    const response = await axios.post(`${ API_URL }/user/save-book`, { bookId, token, approvedData: bookData, coverData });
    return response.data.data;
};

export const getConfig = async (lang, clientId) => {
    const response = await axios.get(`${ API_URL }/frame/config-v2?lang=${ lang }&clientId=${ clientId }`);
    return response.data.data;
};

export const getBookPosition = async (bookId) => {
    setTokenHeader();
    const response = await axios.get(`${ API_URL }/user/get-book-job-positions?bookId=${ bookId }`);
    return response.data.data;
};

export const bookStatus = async (bookId) => {
    setTokenHeader();
    const response = await axios.post(`${ API_URL }/user/status`, {
        bookId: bookId
    });
    return response.data.data;
};

export const moreImages = async (bookId, pageIndex) => {
    setTokenHeader();
    const response = await axios.post(`${ API_URL }/user/add-images`, {
        bookId: bookId,
        pageIndex
    });
    return response.data;
};

export const getJobStatusApi = async (jobId) => {
    setTokenHeader();
    const response = await axios.get(`${ API_URL }/user/job-status`, {
        params: { jobId }
    });
    return response.data;
};

export const getPrebooks = async (phone) => {
    setTokenHeader();
    const response = await axios.post(`${ API_URL }/user/recent-prebooks-v2`, {
        phone
    });
    return response.data.data;
};

export const createCover = async (bookId, coverImageUrl, text, lang) => {
    setTokenHeader();
    const response = await axios.post(`${ API_URL }/user/create-cover`, {
        bookId, coverImageUrl, text, lang
    });
    return response.data;
};

export const sendOtp = async (data) => {
    const response = await axios.post(`${ API_URL }/auth/otp`, data);
    return response.data.data;
};

export const verifyOtp = async (data) => {
    const response = await axios.post(`${ API_URL }/auth/otp-verify`, data);
    return response.data.data;
};
