import styled from "styled-components";

export const EditorPageStyled = styled.div`
    width: 1200px;
    margin: 10px auto;

    @media (max-width: 1200px) {
        width: 100%;
        padding: 1%;
        box-sizing: border-box;
    }
`;


export const NoBookIdStyled = styled.div`

`;

export const HorizontalLine = styled.div`
    max-width: 256px;
    height: 1px;
    margin: 18px auto 0 auto;
    background: rgba(0, 0, 0, 0.12);
`;

export const IsInvalidTokenStyled = styled.div`
    font-size: 33px;
    text-align: center;
`;

export const FinishedStyled = styled.div`
    margin-top: 20px;
    text-align: center;
    font-size: 30px;

    p {
        margin: 5px 0;
    }

    address {
        font-size: 27px;
        color: #4d4d4d;
    }

    img {
        width: 160px;
    }

    .finished-section {
        margin-bottom: 30px;
        padding: 0 2%;
        
        a {
            margin-inline-start: 10px;
        }
    }
`;


export const BackToWebsiteStyled = styled.div`
    margin-top: 20px;
`;

export const PagesWrapperStyled = styled.div`
    margin: 10px 0 20px;

    h1 {
        text-align: center;
        font-weight: 500;
        margin: 20px 0 44px;
        
        @media (max-width: 480px) {
            margin: 20px 0 14px;
        }
    }

    .edit-cover {
        cursor: pointer;
        margin-right: 50px;
        color: rgb(255, 169, 39);
        align-items: center;
        display: flex;
        justify-content: normal;
        gap: 5px;
        user-select: none;

        svg {
            width: 25px;
            height: 25px;
        }

        @media (max-width: 480px) {
            margin-right: 10px;
            margin-bottom: 10px;
            position: relative;
            top: -7px;

            svg {
                width: 15px;
                height: 15px;
            }
        }
    }
`;

export const PreviewActionsStyled = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    grid-gap: 20px;
    position: fixed;
    bottom: 0;
    z-index: 2;
    background: rgb(255, 255, 255);
    box-shadow: rgba(43, 5, 20, 0.05) 0px -6px 14px;
    padding: 12px 16px;
    width: 100%;

    @media (max-width: 480px) {
        display: block;
        padding: 5px 5px 25px 5px;
        box-sizing: border-box;

        button {
            width: 100%;
        }
    }


`;

export const EditActionsStyled = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    grid-gap: 20px;

    @media (max-width: 480px) {
        grid-gap: 10px;
        padding: 5px 25px;
        justify-content: inherit;

        button {
            font-size: 18px;
            line-height: 0.9;
            padding: 8px 12px;
            text-align: center;
            display: block;
            width: 100%;
        }
    }
    @media (max-width: 320px) {
        grid-gap: 10px;
        padding: 5px 15px;
        justify-content: inherit;
    }
`;
