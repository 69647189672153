import { useEffect, useRef, useState } from "react";
import styled from "styled-components";


// Styled components
const Slideout = styled.div`
    margin: 20px 0;

    border-radius: 10px;


    h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        font-family: 'DM Sans', sans-serif;
        letter-spacing: -0.5px;
        color: var(--brand-accent);
    }
`;

const SliderWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;

    p {
        font-size: 12px;
        flex-shrink: 0;
    }

    .current {
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: black;
        font-size: 12px;
        border-radius: 0.8rem;
        aspect-ratio: 1/1;
        background: #e6e6e6;
        margin: 0;
    }
`;

const SliderContainer = styled.div`
    position: relative;
    width: 100%;
    height: 3px;
    background-color: #ddd;
    border-radius: 0px;

    .slidertrack {
        left: 0;
        bottom: 0;
        background: #f59e0b;
        height: 100%;
        width: 0;
    }
`;

const SliderThumb = styled.div`
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f59e0b;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        transform: translateY(-50%) scale(1.1);
    }


    .pulse {
        width: 100%;
        height: 100%;
        background-color: #f59e0b;
        opacity: 0.5;
        border-radius: 200px;
    }

    .currentValue {
        position: absolute;
        display: block;
        top: 150%;
        left: -50%;
        transform: translateX(0%);
        font-size: 10px;
        background-color: #f59e0b;
        border-radius: 5px;
        padding: 5px 10px;
    }

`;

const Range = (props) => {
    const { min, max, value, onChange, lang } = props;

    const [punit, setPUnit] = useState();
    const [current, setCurrent] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [initialSet, s_initialSet] = useState(false);
    const sliderContainerRef = useRef(null);

    useEffect(() => {
        if (punit !== "auto") {
            onChange(Interpolate(min, max, current)[1]);
        }
    }, [current]);

    useEffect(() => {
        if (punit === "auto") {
            setDisabled(true);
            onChange("auto");
        } else {
            onChange(Interpolate(min, max, current)[1]);
            setDisabled(false);
        }
    }, [punit]);


    const handleMouseDown = (event) => {
        event.preventDefault();
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const onTouchStart = (event) => {
        document.addEventListener("touchmove", handleTouchMove, { passive: true });
        document.addEventListener("touchend", handleTouchEnd);
    }
    useEffect(() => {
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("touchmove", handleTouchMove);
            document.removeEventListener("touchend", handleTouchEnd);
        };
    }, []);

    useEffect(() => {
        if (value && !initialSet) {
            if (max > min && value > min) {

                const calculatedPercentage = ((value - min) / (max - min)) * 100;

                setCurrent(calculatedPercentage);
            } else {
                setCurrent(0); // Handle cases where minValue is greater than or equal to maxValue
            }
            s_initialSet(true);
        }
    }, [value]);

    function Interpolate(min, max, current) {
        const newVal = Math.round((current / 100) * (max - min) + min).toFixed(0);
        return [parseInt(newVal), newVal + punit];
    }

    const handleTouchMove = (event) => {
        // event.preventDefault();
        resizeTextHandle(event);
    };

    const handleMouseMove = (event) => {
        resizeTextHandle(event);
    };

    const handleTouchEnd = () => {
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd);
    };

    const resizeTextHandle = (event) => {
        const containerWidth = sliderContainerRef.current.offsetWidth;
        const isRTL = lang === "he";

        const clientX = event.type.includes('touch') ? event.touches[0].clientX : event.clientX;

        // Calculate the position based on the language direction
        const newPosition = isRTL
            ? sliderContainerRef.current.getBoundingClientRect().right - clientX
            : clientX - sliderContainerRef.current.getBoundingClientRect().left;

        let newCurrent = (newPosition / containerWidth) * 100;
        newCurrent = Math.min(Math.max(newCurrent, 0), 100);
        setCurrent(newCurrent);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    if (current !== undefined) {
        return (
            <Slideout>
                <h3>{ props.label }</h3>
                <SliderWrap>

                    <SliderContainer ref={ sliderContainerRef }>
                        <div className="slidertrack" style={ { width: `${ current }%` } }></div>
                        <SliderThumb
                            onMouseDown={ handleMouseDown }
                            onTouchStart={ onTouchStart }
                            style={ (lang === "en" ? { left: `${ current }%` } : { right: `${ current }%` }) }>

                            <div className="pulse" style={ { transform: `scale(${ current / 40 })` } }></div>

                        </SliderThumb>
                    </SliderContainer>

                </SliderWrap>
            </Slideout>
        );
    }
};


export default Range;
