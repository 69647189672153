import React from 'react';
import styled from "styled-components";
import {ButtonStyled} from "../../../styles/CommonStyled";

const WrapperStyled = styled.div`
    min-width: 600px;
    max-width: 650px;
    min-height: 200px;
    grid-gap: 12px;
    margin-top: 12px;
    padding: 0 40px;

    h3 {
        font-size: 25px;
        margin: 5px 0 0 0;
    }

    @media (max-width: 767px) {
        min-width: 100%;
        grid-gap: 5px;
        justify-items: center;
        box-sizing: border-box;
        padding: 0;
    }
    
    .buttonWrapper{
        width: 100%;
        display: grid;
        justify-content: center;
    }
`;

const WrapperItemsStyled = styled.ul`
    font-size: 24px;
    margin-top: 30px;
    text-align-last: start;
    text-align: initial;

    li {
        margin-bottom: 5px;
    }


    @media (max-width: 767px) {
        padding: 5px 23px 0 0;
        font-size: 21px;
    }
`;

const ItemStyled = styled.li`

`;

const CheckBoxWrapperStyled = styled.div`
    margin: 13px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-gap: 5px;
    
    input, label{
        line-height: 1;
    }
`;

const Tutorial = ({langData, onClose}) => {
    return (
        <WrapperStyled>
            <h3>{langData?.tutorial_title}</h3>
            <WrapperItemsStyled>
                {langData.tutorial_items.map((item, idx) => {
                    return (
                        <ItemStyled key={item + idx}>
                            {item}
                        </ItemStyled>
                    );
                })}
            </WrapperItemsStyled>
            <div className="buttonWrapper">
                <ButtonStyled
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                    }}
                >
                    {langData.tutorial_button_text}
                </ButtonStyled>
                <CheckBoxWrapperStyled>
                    <input
                        onChange={()=>{
                            if(localStorage.getItem("tutorialModalv2")) {
                                localStorage.removeItem("tutorialModalv2")
                            }else {
                                localStorage.setItem("tutorialModalv2", "1");
                            }
                        }}
                        aria-checked={!!localStorage.getItem("tutorialModalv2")}
                        aria-labelledby="scales2"
                        type="checkbox" id="scales2"/>
                    <label htmlFor="scales2">{langData.tutorial_checkbox}</label>
                </CheckBoxWrapperStyled>
            </div>
        </WrapperStyled>
    );
};

export default Tutorial;