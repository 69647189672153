import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import BookCoverPrev from "./BookCoverPrev";
import { calculateFontSize, fixSpacing, transformImage } from "../../../../common/utils";
import { MainContext } from "../../../../ContextState";

const WrapperStyled = styled.div`
    &.ltr {
        .book {
            transition: opacity 0.4s 0.2s;
            perspective: 250vw;
        }

        .page {
            //background-color: #111111;
            float: left;
            margin-bottom: 0.5em;
            background: left top no-repeat;
            background-size: cover;
            background-color: #fff;
            box-shadow: 1px 2px 3px 0 rgba(22, 18, 8, 0.20);
        }

        .page:nth-child(even) {
            clear: both;
        }

        .book .pages {
            //width: 946px;
            //height: 473px;
            position: relative;
            transform-style: preserve-3d;
            backface-visibility: hidden;
            border-radius: 4px;
            margin: auto;
            right: 24%;
            transition: right 0.5s ease-in-out;
        }

        .book .page {
            float: none;
            clear: none;
            margin: 0;
            position: absolute;
            top: 0;
            width: 363px;
            height: 363px;
            transform-origin: 0 0;
            transition: transform 1.4s;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            cursor: pointer;
            user-select: none;
            //background-color: #f0f0f0;
        }

        .book .page:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0);
            transition: background 0.7s;
            z-index: 2;
        }

        .book .page:nth-child(odd) {
            pointer-events: all;
            transform: rotateY(0deg);
            right: 0;
            border-radius: 0 4px 4px 0;
            background-image: linear-gradient(to right, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 0) 10%);
        }

        .book .page:nth-child(odd):hover {
            transform: rotateY(-15deg);
        }

        .book .page:nth-child(odd):hover:before {
            background: rgba(0, 0, 0, 0.03);
        }

        .book .page:nth-child(odd):before {
            background: rgba(0, 0, 0, 0);
        }

        .book .page:nth-child(even) {
            pointer-events: none;
            transform: rotateY(180deg);
            transform-origin: 100% 0;
            left: 0;
            border-radius: 4px 0 0 4px;
            //border-color: black;
            //background-image: linear-gradient(to left, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 10%);
        }

        .book .page:nth-child(even):before {
            background: rgba(0, 0, 0, 0.2);
        }

        .book .page.grabbing {
            transition: none;
        }

        .book .page.flipped:nth-child(odd) {
            pointer-events: none;
            transform: rotateY(-180deg);
        }

        .book .page.flipped:nth-child(odd):before {
            background: rgba(0, 0, 0, 0.2);
        }

        .book .page.flipped:nth-child(even) {
            pointer-events: all;
            transform: rotateY(0deg);
        }

        .book .page.flipped:nth-child(even):hover {
            transform: rotateY(15deg);
        }

        .book .page.flipped:nth-child(even):hover:before {
            background: rgba(0, 0, 0, 0.03);
        }

        .book .page.flipped:nth-child(even):before {
            background: rgba(0, 0, 0, 0);
        }

        .page:nth-child(odd) {
            background-position: right top;
        }

        img {
            width: 100%;
        }

    }

    &.rtl {
        .book {
            transition: opacity 0.4s 0.2s;
            perspective: 250vw;
        }

        .page {
            float: left;
            margin-bottom: 0.5em;
            background: left top no-repeat;
            background-size: cover;
            background-color: #fff;
            box-shadow: 1px 2px 3px 0 rgba(22, 18, 8, 0.20);
        }

        .page:nth-child(even) {
            clear: both;
        }

        .book .pages {
            //width: 727px;
            //height: 365px;
            position: relative;
            transform-style: preserve-3d;
            backface-visibility: hidden;
            border-radius: 4px;
            margin: auto;
            left: 24%;
            transition: left 0.5s ease-in-out;
        }

        .book .page {
            float: none;
            clear: none;
            margin: 0;
            position: absolute;
            top: 0;
            width: 363px;
            height: 363px;
            transform-origin: 0 0;
            transition: transform 1.4s;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            cursor: pointer;
            user-select: none;
            //background-color: #f0f0f0;
        }

        .book .page:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0);
            transition: background 0.7s;
            z-index: 2;
        }

        .book .page:nth-child(odd) {
            pointer-events: all;
            transform: rotateY(0deg);
            transform-origin: right;
            //right: 0;
            left: 0;
            border-radius: 4px 0 0 4px;
            background-image: linear-gradient(to left, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 0) 10%);
        }

        .book .page:nth-child(odd):hover {
            transform: rotateY(15deg);
            //transform: rotate3d(0, -1, 0, -16deg);
            transform-origin: right;
        }

        .book .page:nth-child(odd):hover:before {
            background: rgba(0, 0, 0, 0.03);
        }

        .book .page:nth-child(odd):before {
            background: rgba(0, 0, 0, 0);
        }

        .book .page:nth-child(even) {
            pointer-events: none;
            transform: rotateY(-180deg);
            transform-origin: 0 100%;
            //left: 0;
            right: 0;
            border-radius: 0 4px 4px 0;
            //border-color: black;
            //background-image: linear-gradient(to left, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 10%);
        }

        .book .page:nth-child(even):before {
            background: rgba(0, 0, 0, 0.2);
        }

        .book .page.grabbing {
            transition: none;
        }

        .book .page.flipped:nth-child(odd) {
            pointer-events: none;
            transform: rotateY(180deg);
        }

        .book .page.flipped:nth-child(odd):before {
            background: rgba(0, 0, 0, 0.2);
        }

        .book .page.flipped:nth-child(even) {
            pointer-events: all;
            transform: rotateY(0deg);
        }

        .book .page.flipped:nth-child(even):hover {
            transform: rotateY(-15deg);
        }

        .book .page.flipped:nth-child(even):hover:before {
            background: rgba(0, 0, 0, 0.03);
        }

        .book .page.flipped:nth-child(even):before {
            background: rgba(0, 0, 0, 0);
        }

        .page:nth-child(odd) {
            background-position: left top;
        }

        img {
            width: 100%;
        }
    }

    @keyframes moveRight {
        from {
            left: 0;
        }
        to {
            left: 200px; /* Adjust the value based on how far you want to move the element */
        }
    }

    @media (max-width: 767px) {
        width: 100%;
        &.rtl, &.ltr {
            .book {
                .pages {
                    width: 100%;

                    .page {
                        width: 50%;
                    }
                }
            }
        }
    }
`;

const PagesListWrapperStyled = styled.div`
    .book-prev-component {
        .kkop {
            width: 100%;
            height: 100%;
        }
    }
`;

const TxetOageStyled = styled.div`
    height: 100%;

    p {
        text-align: center;
        margin: auto;
        display: grid;
        align-content: center;
        font-size: 14px;
        line-height: 1.3;
        white-space: pre-line;
        height: 100%;
        font-family: "Fredoka", sans-serif;
    }
`;

const MediaOageStyled = styled.div`
    img {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }

    &.lang-img-dpwz-he {
        img {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
`;

const BookPreview = ({ book, bookCoverData, lang, width, langData }) => {
    const pikaRefs = [];

    const [isOpen, s_isOpen] = useState(false);
    const [currentPage, s_currentPage] = useState(0);
    const [firstOpen, s_firstOpen] = useState(true);

    const { bookDataContext } = useContext(MainContext);
    const [bookData] = bookDataContext;
    const { coverData } = bookData;

    useEffect(() => {
        if (!firstOpen) {
            if (currentPage) {
                const idx = currentPage * 2 - 1;
                pikaRefs[idx].current.focus();
            }
            if (currentPage === 0) {
                pikaRefs[0].current.focus();
            }
        }
    }, [currentPage, firstOpen]);

    const bookDataPages = [{ img: bookCoverData?.img }];
    book.forEach(p => {
        bookDataPages.push(p);
    });

    bookDataPages.forEach((pp, inx) => {
        if (inx % 2 === 0) {
            pp.zIndex = bookDataPages.length - inx;
        }
    });

    const pagesStyle = {
        width,
        height: width / 2 + 1.5
    };
    if (isOpen) {
        if (lang === "he") {
            pagesStyle.left = 0;
        } else {
            pagesStyle.right = 0;
        }
    }

    let wrapperClasses = `lang-${ lang }`;
    if (lang === "he") {
        wrapperClasses += " rtl";
    } else {
        wrapperClasses += " ltr";
    }

    const pageDim = parseInt(width / 2, 10);

    return (
        <WrapperStyled className={ wrapperClasses }>
            <div className="book">
                <PagesListWrapperStyled className="pages" role="radiogroup" style={ pagesStyle }>
                    { bookDataPages.map((page, idx) => {
                        const pageNum = idx + 1;
                        const style = { width: pageDim, height: pageDim };
                        if (page.zIndex !== undefined) {
                            style.zIndex = page.zIndex;
                        }

                        pikaRefs[idx] = React.createRef();

                        const isCover = idx === 0;
                        const isImg = page.img && !isCover;
                        const isText = page.text && !isCover;
                        const isSecondPage = idx === 1;

                        const transformedImage = transformImage(page.img, "w_800");

                        let id = "";
                        if (isCover) {
                            id = "book-preview-cover-id";
                        }
                        if (isSecondPage) {
                            id = "book-preview-second-page-id";
                        }

                        const fontSizeCal = calculateFontSize(width / 2);
                        const textPageWidth = "60%";

                        let tabIndexVal = "-1";
                        if (idx === 0) {
                            tabIndexVal = "0";
                        }

                        if (isOpen && idx === 0) {
                            tabIndexVal = "-1";
                        }
                        if (isOpen && (currentPage * 2) === idx) {
                            tabIndexVal = "0";
                        }
                        if (isOpen && (currentPage * 2) - 1 === idx) {
                            tabIndexVal = "0";
                        }

                        let ariaLabel = langData?.arias?.preview_book?.cover;
                        let role = "radio";

                        if (isImg) {
                            ariaLabel = langData?.arias?.preview_book?.image;
                        }
                        if (isText) {
                            ariaLabel = langData?.arias?.preview_book?.text;
                        }
                        if (isCover) {
                            role = "button";
                        }

                        const formattedText = fixSpacing(page?.text);

                        return (
                            <div
                                aria-expanded={ isCover }
                                role={ role }
                                tabIndex={ tabIndexVal }
                                ref={ pikaRefs[idx] }
                                onKeyDown={ (event) => {
                                    switch (event.key) {
                                        case " ": // space
                                        case "Enter": // space
                                            pikaRefs?.[idx]?.current?.click();
                                            break;
                                        default:
                                            break;
                                    }
                                } }
                                aria-label={ ariaLabel }
                                onClick={ (e) => {
                                    s_firstOpen(false);
                                    if (isOpen && idx === 1) {
                                        s_isOpen(false);
                                    } else {
                                        s_isOpen(true);
                                    }
                                    if (idx === bookDataPages.length - 1) {
                                        return;
                                    }
                                    if (pageNum % 2 === 0) {
                                        pikaRefs[idx].current.classList.remove("flipped");
                                        pikaRefs[idx].current.previousElementSibling?.classList?.remove("flipped");
                                    } else {
                                        pikaRefs[idx].current.classList.add("flipped");
                                        pikaRefs[idx].current.nextElementSibling?.classList?.add("flipped");
                                    }
                                    if (isText) {
                                        s_currentPage(currentPage + 1);
                                    } else if (isCover) {
                                        s_currentPage(currentPage + 1);
                                    } else {
                                        s_currentPage(currentPage - 1);
                                    }
                                } }
                                id={ id }
                                className="page"
                                key={ idx }
                                style={ style }
                            >
                                { isCover && (
                                    <BookCoverPrev
                                        imageUrl={ transformedImage }
                                        lang={ lang }
                                        text={ bookCoverData.text }
                                        coverDesign={ bookCoverData.coverDesign }
                                        index={ 3 }
                                        isSelected={ false }
                                        partOfBook={ true }
                                        contWidth={ pageDim }
                                        coverData={ coverData }
                                    />) }


                                { isImg && (
                                    <MediaOageStyled className={ `lang-img-dpwz-${ lang }` }>
                                        <img src={ page.img } alt="תמונת עמוד"/>
                                    </MediaOageStyled>
                                ) }
                                { isText && (
                                    <TxetOageStyled>
                                        <p style={ { fontSize: fontSizeCal, width: textPageWidth } }>{ formattedText }</p>
                                    </TxetOageStyled>
                                ) }
                            </div>);
                    }) }
                </PagesListWrapperStyled>
            </div>
        </WrapperStyled>);
};

export default BookPreview;
