import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const WrapperStyled = styled.div`
    width: 50%;
    text-align: center;
    margin: auto;

    .text {
        font-size: 30px;
        margin-top: 15px;
        font-weight: 600;
        
        &:focus{
            outline: 1px solid transparent;
        }
    }

    .subtext {
        font-size: 20px;
    }

    @media (max-width: 767px) {
        width: 100%;
    }
`;

const ChangingTextsProgress = ({ lang }) => {
    const ref = useRef(null);
    const [progress, setProgress] = useState(1);
    const [text, setText] = useState({
        "text": lang.loading_texts.text1,
        "subtext": lang.loading_texts.subtext1
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 100) {
                setProgress(progress + 1);
            } else {
                setProgress(0);
            }
        }, 500);

        if (progress === 0) {
            setText({
                "text": lang.loading_texts.text1,
                "subtext": lang.loading_texts.subtext1
            });
        } else if (progress === 30) {
            setText({
                "text": lang.loading_texts.text2,
                "subtext": lang.loading_texts.subtext2
            });
        } else if (progress === 50) {
            setText({
                "text": lang.loading_texts.text3,
                "subtext": lang.loading_texts.subtext3
            });
        } else if (progress === 70) {
            setText({
                "text": lang.loading_texts.text4,
                "subtext": lang.loading_texts.subtext4
            });
        } else if (progress === 100) {
            setText({
                "text": lang.loading_texts.text5,
                "subtext": lang.loading_texts.subtext5
            });
        }
        return () => clearInterval(interval);

    }, [progress]);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [text]);

    return (
        <WrapperStyled
            className="loading-bar">
            <div
                tabIndex="0"
                className="text"
                ref={ ref }
                aria-label={ text.text }
            >
                { text.text }
            </div>
        </WrapperStyled>
    );
};

export default ChangingTextsProgress;
