export const WarningSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="#FFC43D"/>
        </svg>
    )
}

export const BackSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="0 0 13 24" fill="none">
            <path d="M11.8125 1.375L1.1875 12L11.8125 22.625" stroke="black" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export const CloseButtonSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M25.3332 8.5465L23.4532 6.6665L15.9998 14.1198L8.5465 6.6665L6.6665 8.5465L14.1198 15.9998L6.6665 23.4532L8.5465 25.3332L15.9998 17.8798L23.4532 25.3332L25.3332 23.4532L17.8798 15.9998L25.3332 8.5465Z" fill="black"/>
        </svg>
    )
}

export const ArrowRightButtonSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23" fill="none">
            <path d="M1 22.25L11.625 11.625L1 1" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ArrowLeftButtonSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23" fill="none">
            <path d="M11.625 1L1 11.625L11.625 22.25" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ResetSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
            <path d="M5.28176 9.26708C6.71768 6.86881 9.0053 5.05728 11.7187 4.16979C14.432 3.2823 17.3862 3.37934 20.0312 4.44284C22.6761 5.50634 24.8315 7.46382 26.0961 9.95079C27.3607 12.4378 27.6482 15.2847 26.9051 17.9616C26.1621 20.6385 24.4391 22.9628 22.057 24.5018C19.6749 26.0407 16.7961 26.6895 13.9565 26.3272C11.1169 25.965 8.5101 24.6164 6.62151 22.5325C4.73292 20.4487 3.69124 17.7717 3.69043 14.9999" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.0713 9.28557H5.1665V3.57129" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const EditSvg = () =>{
    return (
        <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             transform="matrix(1, 0, 0, 1, 0, 0)">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC"
               strokeWidth="0.048"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                    stroke="#FFA927" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path
                    d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                    stroke="#FFA927" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </g>
        </svg>
    )
}

export const ClickMeArrow = () =>{
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M8.60112 16.5308C8.89426 16.8234 9.36913 16.823 9.66178 16.5299C9.95442 16.2367 9.95402 15.7619 9.66088 15.4692L8.60112 16.5308ZM5.52988 11.3452C5.23674 11.0526 4.76187 11.053 4.46922 11.3461C4.17658 11.6393 4.17698 12.1141 4.47012 12.4068L5.52988 11.3452ZM4.48682 11.3291C4.18475 11.6125 4.16964 12.0871 4.45306 12.3892C4.73648 12.6912 5.21111 12.7064 5.51318 12.4229L4.48682 11.3291ZM9.64418 8.54694C9.94625 8.26352 9.96136 7.78889 9.67794 7.48682C9.39452 7.18475 8.91989 7.16964 8.61782 7.45306L9.64418 8.54694ZM5 11.126C4.58579 11.126 4.25 11.4618 4.25 11.876C4.25 12.2902 4.58579 12.626 5 12.626V11.126ZM15.37 11.876L15.3723 11.126H15.37V11.876ZM17.9326 10.8234L18.4614 11.3553V11.3553L17.9326 10.8234ZM19.75 8.26907C19.7511 7.85486 19.4163 7.51815 19.0021 7.517C18.5879 7.51586 18.2511 7.85072 18.25 8.26493L19.75 8.26907ZM9.66088 15.4692L5.52988 11.3452L4.47012 12.4068L8.60112 16.5308L9.66088 15.4692ZM5.51318 12.4229L9.64418 8.54694L8.61782 7.45306L4.48682 11.3291L5.51318 12.4229ZM5 12.626H15.37V11.126H5V12.626ZM15.3677 12.626C16.5267 12.6295 17.6395 12.1724 18.4614 11.3553L17.4038 10.2916C16.8641 10.8282 16.1333 11.1283 15.3723 11.126L15.3677 12.626ZM18.4614 11.3553C19.2833 10.5382 19.7468 9.42801 19.75 8.26907L18.25 8.26493C18.2479 9.02598 17.9435 9.755 17.4038 10.2916L18.4614 11.3553Z"
                    fill="#FFA927"></path>
            </g>
        </svg>
    )
}

export const Check = ({ size }) => {
    return <svg width={ size } height={ size } viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-check">
        <path d="M20 6 9 17l-5-5"/>
    </svg>;
};

export const ExitSvg = () => {
    return (
        <svg fill="#FFC43D" width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">

            <g id="SVGRepo_bgCarrier" strokeWidth="0"/>

            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>

            <g id="SVGRepo_iconCarrier">
                <path d="M12.207 9H5V7h7.136L11.05 5.914 12.464 4.5 16 8.036l-3.536 3.535-1.414-1.414L12.207 9zM10 4H8V2H2v12h6v-2h2v4H0V0h10v4z" fillRule="evenodd"/>
            </g>

        </svg>
    );
};
