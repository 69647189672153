import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ClickOnMe from "../../editor/components/ClickOnMe";
import BookPreview from "../../editor/components/book/BookPreview";
import Button from "./Button";
import { MainContext } from "../../../ContextState";
import { getMobileQuery } from "../../../common/utils";


const WrapperStyled = styled.div`
    width: 727px;
    min-height: 320px;
    display: grid;
    grid-gap: 12px;
    margin-top: 25px;

    @media (max-width: 1130px) {
        margin: auto;
    }

    @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
    }
`;

const CheckBoxStyled = styled.div`
    width: 300px;
    margin: auto;

    input {
        display: inline-block;
        vertical-align: top;
    }

    label {
        display: inline-block;
        //line-height: 1.2;
        vertical-align: top;
        margin-inline-start: 5px;
    }

    .terms-link {
        cursor: pointer;
        color: #5d35ff;
    }
`;

export const GettingPaidStyled2 = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 10px auto 0;
    grid-gap: 18px;

    @media (max-width: 767px) {
        margin: 5px auto 20px;
        //display: block;
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 480px) {

    }

    .vertical-pay {
        @media (max-width: 767px) {
            display: none;
        }
    }

    &.lang-en {
        .pay-book {
            .cont {
                @media (max-width: 767px) {
                    grid-template-rows: auto 51px 46px;
                }
                
                h3 {
                    @media (max-width: 767px) {
                        font-size: 20px;
                    }
                }
                
                .choose-cover{
                    font-size: 17px;
                    height: max-content;
                    padding: 9px 6px;
                    width: 100%;
                }
                
                .price{
                    text-decoration: underline;
                }
            }
        }
    }

    .cont {
        position: relative;
        text-align: center;
        display: grid;
        justify-items: center;

        @media (max-width: 767px) {
            justify-items: center;
            grid-template-rows: 25px 64px 46px;
        }

        h3 {
            font-size: 33px;
            margin: 5px 0;

            @media (max-width: 767px) {
                width: 100%;
                font-size: 24px;
            }
        }

        p {
            font-size: 19px;
            min-height: 70px;
            margin: 0 0 1px;

            @media (max-width: 767px) {
                min-height: auto;
                display: none;
            }
        }
    }


    .hard {
        margin-bottom: 5px;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    .price {
        position: relative;
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 5px;

        @media (max-width: 767px) {
            margin: 10px 0 2px;
            font-size: 22px;
        }
        @media (max-width: 410px) {
            font-size: 18px;
        }
    }

    .pay-book {
        &.pdf {
            @media (max-width: 767px) {
                .price {
                    margin-bottom: 2px;
                }
            }
        }
    }

    .sub-title {
        position: absolute;
        font-size: 14px;
        width: max-content;
        bottom: -6px;
        left: 1px;

        @media (max-width: 767px) {
            font-size: 12px;
            top: 20px;
            bottom: inherit;
        }
        @media (max-width: 340px) {
            top: inherit;
            bottom: -5px;
        }
    }

    &.desc {
        @media (max-height: 744px) {
            margin-top: 2px;

            .pay-book .cont {
                h3 {
                    font-size: 24px;
                }

                p {
                    min-height: auto;
                    font-size: 14px;
                }

                .price {
                    font-size: 18px;

                    .sub-title {
                        font-size: 12px;
                    }
                }
            }
        }
    }
`;

export const VerticalLineStyled = styled.div`
    height: 200px;
    width: 1px;
    background: rgba(0, 0, 0, 0.12);

    &.vertical-line-stage2 {
        margin-top: 112px;
        margin-inline-start: 9px;
        margin-inline-end: 33px;
    }

    @media (max-width: 1000px) {
        display: none;
    }
`;

const PreviewBookAndPay = ({
                               selectedBookCoverImage,
                               bookData,
                               lang,
                               langData,
                               textData,
                               imagesData,
                               chooseBookHandle,
                               onTermsClick,
                               loading
                           }) => {
    const wrapperRef = useRef(null);
    const termsRef = useRef(null);

    const { configContext } = useContext(MainContext);
    const [config] = configContext;

    const [width, s_width] = useState(0);
    const [onFocus] = useState(0);

    const innerWidth = getMobileQuery();
    const isMobile = innerWidth < 550;

    useEffect(() => {
        if (wrapperRef?.current) {
            s_width(wrapperRef.current.offsetWidth);
        }
    }, [wrapperRef]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (onFocus) {
                switch (event.key) {
                    case " ": // space
                    case "Enter":
                        onTermsClick();
                        break;
                    default:
                        break;
                }
            }
        };

        const divElement = termsRef.current;

        if (divElement) {
            divElement.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [onFocus]);

    const gender = bookData.inputData.gender;

    const templateTitle = gender === "female" ? bookData.template?.titleFemale : bookData.template?.titleMale;
    const coverDesign = bookData.template.coverDesign;
    const book = [];
    textData.forEach((text, idx) => {
        book.push({ img: imagesData[idx] });
        book.push({ text: text });
    });

    let bookPrice = "";
    let pdfPrice = "";
    if (lang === "he") {
        bookPrice = `${ config.priceSymbol }${ config.bookPrice } ${ langData.end_process.price_book } ${ config.priceSymbol }${ config.deliveryPrice }`;
        pdfPrice = `${ config.priceSymbol }${ config.pdfPrice } ${ langData.end_process.price_pdf }`;
    } else {
        bookPrice = `${ config.priceSymbol }${ config.bookPrice } ${ langData.end_process.price_book }  ${ config.priceSymbol }${ config.deliveryPrice }`;

        if (config.deliveryPrice === 0) {
            bookPrice = `${ config.priceSymbol }${ config.bookPrice } ${ langData.end_process.free_delivery }`;
        }

        pdfPrice = `${ langData.end_process.price_pdf } ${ config.priceSymbol }${ config.pdfPrice }`;
    }

    return (
        <WrapperStyled
            ref={ wrapperRef }>
            <ClickOnMe
                langData={ langData }
                lang={ lang }
            />
            <BookPreview
                width={ width }
                bookCoverData={
                    {
                        img: selectedBookCoverImage,
                        text: templateTitle,
                        coverDesign
                    }
                }
                lang={ lang }
                langData={ langData }
                book={ book }
            />
            <GettingPaidStyled2 className={ `lang-${ lang } ${ isMobile ? "mob" : "desc" }` }>
                <div className="pay-book hard">
                    <div className="cont">
                        <h3>{ langData.end_process.choose_cover_hard }</h3>
                        <p>{ langData.end_process.p_description_hard }</p>
                        <div className="price">
                            { bookPrice }
                            <span className="sub-title">{ langData.end_process.price_book_sub }</span>
                        </div>
                        <Button
                            ariaLabel={ `${ langData.end_process.choose_cover_hard } ${ bookPrice }` }
                            loading={ loading === "makingCover_book" }
                            not_clickable={ loading === "makingCover_pdf" }
                            onClick={ () => chooseBookHandle("book") }
                            className="next-button choose-cover"
                        >
                            { langData.choose_hard_cover }
                        </Button>
                    </div>
                </div>
                <VerticalLineStyled className="vertical-pay"/>
                <div className="pay-book pdf">
                    <div className="cont">
                        <h3>{ langData.end_process.choose_cover_pdf }</h3>
                        <p>{ langData.end_process.p_description_pdf }</p>
                        <div className="price">
                            { pdfPrice }
                        </div>
                        <Button
                            ariaLabel={ `${ langData.end_process.choose_cover_pdf } ${ pdfPrice }` }
                            loading={ loading === "makingCover_pdf" }
                            not_clickable={ loading === "makingCover_book" }
                            onClick={ () => chooseBookHandle("pdf") }
                            className="next-button choose-cover"
                        >
                            { langData.choose_e_book }
                        </Button>
                    </div>
                </div>
            </GettingPaidStyled2>
        </WrapperStyled>
    );
};

export default PreviewBookAndPay;
